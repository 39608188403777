import React, { useState, useEffect }  from 'react';
import { StyleSheet, View } from 'react-native';
import { Amplify } from 'aws-amplify';
import awsmobile from './src/aws-exports';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import SignIn from "./src/components/user/SignIn";
import NavigationBar from "./src/components/navigation/NavigationBar";
import Companies from "./src/components/modules/companies/companies";
import USERS from "./src/components/modules/users/users";

// Amplify.configure(awsmobile);

// Overwrite aws_appsync_graphqlEndpoint using environment variable with fallback
const appsyncGraphqlEndpoint = process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT || "https://2bgexy554jaydide3m4uklvv7e.appsync-api.us-west-2.amazonaws.com/graphql"

Amplify.configure({
  ...awsmobile,  // Keep other existing configuration
  aws_appsync_graphqlEndpoint: appsyncGraphqlEndpoint,  // Overwrite the GraphQL endpoint
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
});

function App() {
  const [user, setUser] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        if (currentUser) {
          setUser(currentUser);
        }
      } catch (error) {
        console.log('Error checking current user:', error);
      }
    };

    checkCurrentUser();
  }, []);

  const handleSignIn = (user) => {
    setUser(user);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
      setSelectedComponent(null);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const handleSelectComponent = (component) => {
    // console.log('Selected component in App.js:', component);
    setSelectedComponent(component);
  };

  const renderComponent = () => {
    if (!selectedComponent) {
      return null;
    }

    // Use the name property to match components
    switch (selectedComponent.name) {
      case 'Companies':
        return <Companies />;
      case 'Users':
        return <USERS />;
      default:
        console.log('No matching component for:', selectedComponent.name);
        return null;
    }
  };

  if (!user) {
    return <SignIn onSignIn={handleSignIn} />;
  }

  return (
    <View style={styles.container}>
      <NavigationBar 
        onSelectComponent={handleSelectComponent}
        onSignOut={handleSignOut}
      />
      <View style={styles.content}>
        {renderComponent()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 20,
  },
});

export default App;
