export const listCompanies = /* GraphQL */ `
  query ListCompanies {
    listCompanies {
    company_id
    company_name
    primary_ticker
    primary_exchange
    secondary_ticker
    secondary_exchange
    sa_ticker
  }
}
`;

export const listAlphaPilotUsers = /* GraphQL */ `
  query ListAlphaPilotUsers {
    listAlphaPilotUsers {
      cognito_username
      email
      first_name
      last_name
      email_verified
      confirmation_status
      account_status
      groups
      agreements
      nda_required
      nda_accepted_datetime_utc
      group_membership_ExcelAddinAccess
      group_membership_UploadTranscriptAccess
      group_membership_ViewAnalytics
      group_membership_ViewTranscriptAccess
      most_recent_event_datetime_utc
    }
  }
`;
